<template>
    <v-dialog v-model="dialog" scrollable max-width="600">
        <v-card>
            <v-toolbar dark>
                <v-card-title>
                    <span>Mark as sent</span>
                    <hr>
                </v-card-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="closeDialog">
                        <i class="fa fa-close"></i>
                    </v-btn>
                </v-toolbar-items>
            </v-toolbar>

            <v-card-text>
                <v-container>
                    <v-row class="mt-2">
                        <v-col cols="12" sm="6" md="12">
                            <label>
                                Remarks
                            </label>
                            
                            <ckeditor 
                                :config="editorConfig"
                                v-model="shipmentCertificateRequest.sent_remarks" >
                            </ckeditor>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn dark x-large @click="markAsSent()" :loading="loading">
                    Save
                </v-btn>
                <v-btn x-large text @click="closeDialog">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
  
<script>
import ShipmentCertificateRequestService from "@/services/shipment-certificate-request/ShipmentCertificateRequestService";

const shipmentCertificateRequest=new ShipmentCertificateRequestService();

export default {
    data() {
        return {
            dialog: false,
            loading: false,
            shipmentCertificateRequestId: null,
            shipmentCertificateRequest: {
                sent_remarks: ''
            },
            editorConfig: {
                toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ],
                versionCheck: false
            },
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
        },
        resetForm(){
            this.shipmentCertificateRequestId= null;
            this.shipmentCertificateRequest= {
                sent_remarks: ''
            };
        },
        openDialog(shipmentCertificateRequestId) {
            this.resetForm();
            this.dialog = true;
            this.shipmentCertificateRequestId = shipmentCertificateRequestId;
        },
        markAsSent() {
            this.$confirm({
                message: `Are you sure to mark as sent? `,
                button: {
                    no: "No",
                    yes: "Yes",
                },
                callback: (confirm) => {
                    this.loading = true;

                    shipmentCertificateRequest
                    .markAsSent(this.shipmentCertificateRequestId, this.shipmentCertificateRequest)
                    .then(response => {
                        this.$snotify.success("Marked as sent");
                        this.loading = false;
                        this.$emit('refresh');
                        this.closeDialog();
                    })
                    .catch((err) => {
                        this.loading = false;
                        this.$snotify.error("Something went wrong");
                    });
                },
            });
        }
    }
}
</script>