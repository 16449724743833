<template>
  <v-dialog v-model="dialog" fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      scrollable>
    <v-card>
      <v-toolbar flat>
        <v-card-title>
          <span>Assign Tracking Number</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <h6><strong>Note:</strong></h6>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title><strong>Paid Amount:</strong> {{shipment_certificate_request.currency}}{{shipment_certificate_request.amount_total}}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea dense outlined v-model="shipping.tracking_code" label="Tracking Code" :error="$v.shipping.tracking_code.$error"></v-textarea>
              <span class="text-danger" v-if="$v.shipping.tracking_code.$error">This information is required</span>
            </v-col>
            <v-col cols="12" md="12">
              <v-textarea dense outlined v-model="shipping.tracking_url" label="Tracking Url" :error="$v.shipping.tracking_url.$error"></v-textarea>
              <span class="text-danger" v-if="$v.shipping.tracking_url.$error">This information is required</span>
            </v-col>
            <v-col cols="12" md="12">
              <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field dense outlined
                      v-model="shipping.expected_delivery_date"
                      label="Expected Delivery Date" :error="$v.shipping.expected_delivery_date.$error"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="shipping.expected_delivery_date"
                    @input="menu = false"
                ></v-date-picker>
              </v-menu>
              <span class="text-danger" v-if="$v.shipping.expected_delivery_date.$error">This information is required</span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="btn btn-primary"
            dark
            medium
            @click="assignTrackingNumber()"
            :loading="loading"
        >
          Add Tracking Number
        </v-btn>
        <v-btn
            color="btn btn-standard"
            dark
            medium
            @click="closeDialog">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import ShipmentCertificateRequestService from "@/services/shipment-certificate-request/ShipmentCertificateRequestService";
import {required, requiredIf} from "vuelidate/lib/validators";
const shipmentCertificateRequest=new ShipmentCertificateRequestService();
export default {
  validations:{
    shipping:{
      tracking_code:{required},
      tracking_url:{required},
      expected_delivery_date:{required},
    }
  },
  data(){
    return{
      dialog:false,
      shipping:{
        tracking_code:'',
        tracking_url:'',
        expected_delivery_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      shipment_certificate_request:{},
      loading:false,
      menu:false,
    }
  },
  methods:{
    addTrackingNumber(item){
      this.resetForm();
      this.dialog=true;
      this.shipment_certificate_request=item;

    },
    closeDialog(){
      this.dialog=false;
    },
    resetForm(){
      this.shipment_certificate_request=false;
      this.shipping={
        tracking_code:'',
        tracking_url:'',
        expected_delivery_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      };
    },
    assignTrackingNumber(){
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 6000);
      } else {
        this.loading=true;
        shipmentCertificateRequest
            .dispatchShipmentCertificateRequest(this.shipment_certificate_request.id, this.shipping)
            .then(response => {
              this.resetForm();
              this.$snotify.success("Assign Tracking Number Successfully");
              this.closeDialog();
              this.$emit('refresh');
            })
            .catch((err) => {

            }).finally(() => {
          this.loading = false;
        });
      }

    }
  }
}
</script>

