<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>International certificate shipment  report</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ International certificate shipment  report
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">

                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
                      <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Candidate Name"
                      v-model="search.candidate_name"
                      outlined
                      dense
                      v-on:keyup.enter="searchShipmentCertificateRequest()"
                      @input="search.candidate_name = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Transaction Id"
                      v-model="search.transaction_id"
                      outlined
                      dense
                      v-on:keyup.enter="searchShipmentCertificateRequest()"
                      @input="search.transaction_id = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Enrolment key"
                      v-model="search.exam_key"
                      outlined
                      dense
                      v-on:keyup.enter="searchShipmentCertificateRequest()"
                      @input="search.exam_key = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="3" v-if="currentUser.access_type!='score'">
                  <v-select
                      label="SCORE"
                      v-model="search.score_id"
                      outlined
                      dense :items="scores" item-value="id" item-text="name"
                      v-on:keyup.enter="searchShipmentCertificateRequest()"
                      @input="search.score_id = $event !== null ? $event : ''"
                      clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-select
                      label="Priority"
                      v-model="search.priority"
                      outlined
                      dense :items="priorities" item-value="value" item-text="text"
                      v-on:keyup.enter="searchShipmentCertificateRequest()"
                      @input="search.priority = $event !== null ? $event : ''"
                      clearable
                  ></v-select>
                </v-col>
                <!-- <v-col cols="12" sm="6" md="3">
                  <v-select
                      label="Type"
                      v-model="search.type"
                      outlined
                      dense :items="types" item-value="value" item-text="text"
                      v-on:keyup.enter="searchShipmentCertificateRequest()"
                      @input="search.type = $event !== null ? $event : ''"
                      clearable
                  ></v-select>
                </v-col> -->
                <v-col cols="12" sm="6" md="3">
                  <v-select
                      label="Status"
                      v-model="search.status"
                      outlined
                      dense 
                      :items="statuses" 
                      item-value="value" 
                      item-text="name"
                      v-on:keyup.enter="searchShipmentCertificateRequest()"
                      @input="search.type = $event !== null ? $event : ''"
                      clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="text-right">
                  <v-btn @click.prevent="searchShipmentCertificateRequest()"
                      class="btn btn-primary text-white"
                      :isLoading="isLoading">
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </div>
            </div>
            <div class="table-responsive">
              <v-skeleton-loader
                  type="table-thead"
                  v-if="isLoading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="isLoading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Enrolment key	</th>
                  <th class="px-3">Exam name</th>
                  <th class="px-3">Payment method	</th>
                  <th class="px-3">Order Placed Date</th>
                  <th class="px-3">Address</th>
                  <th class="px-3">Paid Amount</th>
                  <th class="px-3">Shipping Option</th>
                  <th class="px-3">Score</th>
                  <th class="px-3">Status</th>
                  <th class="px-3">Options</th>
                </tr>
                </thead>
                <template>
                  <tr v-if="shipment_certificate_request.length > 0" v-for="item in shipment_certificate_request">
                    <td class="px-2">
                      <div class="ml-2">
                        <strong>{{ item.candidate_full_name }}</strong>
                      </div>
                      <a @click="getCandidateSummary(item.exam_key)" class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.exam_key }}
                      </a>
                    </td>
                    <td class="px-2">
                      <span  class=" font-weight-bolder text-hover-primary mb-1">
                        {{item.product_name }}
                      </span>
                    </td>
                    <td class="px-2">
                      <p class="font-weight-bold">
                        {{item.payment_method_text }}
                      </p>
                    </td>
                    <td class="px-2">
                      {{item.order_date}}
                    </td>
                    <td class="px-2">
                      <p  class=" font-weight-bolder  mb-1" v-html="item.full_address_format">
                      </p>
                    </td>
                    <td class="px-2">
                      <span  class="subtitle-2">
                        {{item.currency}}{{item.amount_total }}
                      </span>
                    </td>

                    <td class="px-2">
                      <a  class="text-primary font-weight-bolder text-hover-primary mb-1">
                        {{item.shipment_method_text }}
                      </a>
                    </td>
                    <td class="px-2">
                      <span  class="font-weight-bolder  mb-1">
                        {{item.score_name }}
                      </span>
                    </td>
                    <td class="px-2">
                      <span class="badge" :class="item.is_sent ? 'badge-success' : 'badge-warning'"> {{ item.is_sent ? 'Sent' : 'Pending'}} </span>
                    </td>

                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a @click="viewInvoice(item.cart_id,item.user_id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-file-invoice"></i>
                                </span>
                                <span class="navi-text">View Invoice</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text v-if="!item.is_sent" tag="div" class="navi-item">
                              <a @click="markAsSent(item.id)" class="navi-link">
                                <span class="navi-icon">
                                    <i class="fa fa-check-circle"></i>
                                </span>
                                <span class="navi-text">Mark as sent</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="shipment_certificate_request.length == 0">
                    <td colspan="10" class="text-center">
                      <strong>No items added</strong>
                    </td>
                  </tr>
                </template>
              </table>
              <b-pagination
                  v-if="shipment_certificate_request.length > 0"
                  class="pull-right mt-7"
                  @input="getAllShipmentCertificateRequest"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>
          </div>
        </div>
      </div>
      <add-tracking ref="assign-tracking" @refresh="getAllShipmentCertificateRequest"></add-tracking>
      <mark-as-sent ref="mark-as-sent" @refresh="getAllShipmentCertificateRequest"></mark-as-sent>
    </div>
  </v-app>
</template>
<script>
import ShipmentCertificateRequestService from "@/services/shipment-certificate-request/ShipmentCertificateRequestService";
import AddTracking from "./widget/AddTracking";
import AccountholderService from "@/services/user/accountholder/AccountholderService";
import ScoreService from "@/services/score/score/ScoreService";
import MarkAsSent from "./widget/MarkAsSent";

const score=new ScoreService();
const accountHolder=new AccountholderService();
const shipmentCertificateRequest=new ShipmentCertificateRequestService();

export default {
  components: {
    AddTracking, MarkAsSent
  },
  data(){
    return{
      isLoading:false,
      search:{
        candidate_name:'',
        exam_key:'',
        score_id:'',
        type:'all',
        status: ''
      },
      shipment_certificate_request:[],
      total: null,
      perPage: null,
      page: null,
      scores:[],
      types:[
        {value:'pending',text:'Pending'},
        {value:'dispatched',text:'Dispatched'},
        {value:'completed',text:'Completed'},
      ],
      priorities:[
        {value:'regular',text:'Regular'},
        {value:'express',text:'Express'},
      ],
      currentUser:{},
      statuses:[
        {
          name: 'Sent',
          value: 'sent'
        },
        {
          name: 'Pending',
          value: 'pending'
        }
      ]
    }
  },
  methods:{
    searchShipmentCertificateRequest(){
      this.getAllShipmentCertificateRequest();
    },
    getAllShipmentCertificateRequest(){
      shipmentCertificateRequest
          .paginate(this.search,this.page)
          .then(response => {
            this.shipment_certificate_request=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          }).finally(() => {
        this.isLoading = false;
      });
    },
    assignTrackingNumber(item){
      this.$refs['assign-tracking'].addTrackingNumber(item);
    },
    printShipmentCertificate(itemId){
      this.$confirm({
        message: `Are you sure ? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            shipmentCertificateRequest
                .printShipmentCertificateRequest(itemId,null)
                .then((response) => {
                  this.getAllShipmentCertificateRequest();
                  this.$snotify.success("Shipment Certificate Request Successfully");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                })
          }
        },
      });
    },
    viewInvoice(cartId,userId) {
      accountHolder
          .viewInvoice(userId, cartId);
    },
    getAllScore() {
      score
          .getScores()
          .then(response => {
            this.scores = response.data.scores;
          });
    },
    getCurrentUser() {
      this.currentUser =  this.$store.getters.currentUser;
    },
    getCandidateSummary(examKey){
      this.$router.push({
          name: 'candidate-summary',
          params: {examKey: examKey}
      })
    },
    markAsSent(id){
      this.$refs['mark-as-sent'].openDialog(id);
    }
  },
  mounted() {
    this.getAllShipmentCertificateRequest();
    this.getCurrentUser();
    this.getAllScore();
  }
}
</script>