import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class ShipmentCertificateRequestService {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/shipment-certificate-request';
    }
    paginate(data={},index = null) {
        let url = `${this.#api}`;
        if (index != null)
            url = `${url}?page=${index}`;
        let param ={
            params: data
        }
        return apiService.query(url,param);
    }
    update(id, data) {
        let url = `${this.#api}/${id}`;
        return apiService.put(url, data)
    }
    dispatchShipmentCertificateRequest(id, data){
        let url = `${this.#api}/${id}/dispatch`;
        return apiService.post(url, data)
    }
    printShipmentCertificateRequest(id,data){
        let url = `${this.#api}/${id}/print`;
        return apiService.post(url, data);
    }
    markDelivered(id,data){
        let url = `${this.#api}/${id}/mark-delivered`;
        return apiService.post(url, data);
    }
    markAsSent(id,data){
        let url = `${this.#api}/${id}/mark-sent`;
        return apiService.post(url,data);
    }
}